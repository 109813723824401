<template>
  <div>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <section class="hero">
          <div class="hero-body">
            <div class="container">
              <h1 data-text="Matz Radloff">
                Matz Radloff
              </h1>
              <h2>
                Programmer
              </h2>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style>

@import url('https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900');

h1 {
text-transform: Uppercase;
margin-bottom: .5em;
font-family: 'Rubik', sans-serif;
font-size: 4rem;
color: #E4E5E6;
position: relative;
background: linear-gradient(to right, #24243e, #141E30, #0f0c29);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
z-index: auto;
}

h1:before,
h1:after {
content: attr(data-text);
position: absolute;
top: 0;
left: 0;
}

h1:before {
z-index: -1;
text-shadow: -0.001em -0.001em 1px rgba(255,255,255,.15);
}

h1:after {
z-index: -2;
text-shadow: 10px 10px 10px rgba(0,0,0,.5), 20px 20px 20px rgba(0,0,0,.4), 30px 30px 30px rgba(0,0,0,.1);
mix-blend-mode: multiply; }

h2 {
margin-top: -1.15em;
font-family: 'Pirata One', cursive;
font-size: 3rem;
color: #F7971E;
text-align: center;}

</style>

<script>

export default {
  data() {
    return {
      test: 'rofl',
    }
  },
  created() {
    console.log('LOADED HOME')
  }
}

</script>
